<template>
  <div class="hello">
    <div class="listtitle">
      <img src="../assets/title.png" alt="" />
    </div>
    <div class="list">
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzU1NDkwODE5Mg==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 41px" src="../assets/dyyy.png" alt="" /> <br />
          <h4>市第一人民医院</h4>
        </a>
      </div>
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzUzOTk4Mzg5Ng==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 42px" src="../assets/yxs.png" alt="" /> <br />
          <h4>临床药学室</h4>
        </a>
      </div>
    </div>
    <div class="list">
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzI5ODY0MTc1Ng==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 40px" src="../assets/gdyp.png" alt="" /> <br />
          <h4>肝胆胰脾外科</h4>
        </a>
      </div>
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzI0ODY0NDk1OQ==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 40px" src="../assets/sss.png" alt="" /> <br />
          <h4>手术室</h4>
        </a>
      </div>
    </div>
    <div class="list">
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzkyOTE3MDQ4OA==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 42px" src="../assets/fsmy.png" alt="" /> <br />
          <h4>风湿免疫科</h4>
        </a>
      </div>
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzA3MTEwNTA1OA==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 42px" src="../assets/sjnk.png" alt="" /> <br />
          <h4>神经内科</h4>
        </a>
      </div>
    </div>
    <div class="list">
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzIzNjE5ODc1Nw==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 40px" src="../assets/gyk.png" alt="" /> <br />
          <h4>骨一科</h4>
        </a>
      </div>
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzA4NzgwMjc1NA==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 40px" src="../assets/sjwk.png" alt="" /> <br />
          <h4>神经外科</h4>
        </a>
      </div>
    </div>
    <div class="list">
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzI5MDYxMDAwMg==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 40px" src="../assets/xxg.png" alt="" /> <br />
          <h4>心血管内科</h4>
        </a>
      </div>
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzI3MjYyMjUxOQ==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 40px" src="../assets/xse.png" alt="" /> <br />
          <h4>新生儿科</h4>
        </a>
      </div>
    </div>
    <div class="list">
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzIxMDIwNzE4OQ==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 32px" src="../assets/yk.png" alt="" /> <br />
          <h4>眼科医院</h4>
        </a>
      </div>
      <div class="title">
        <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzU2NDg3NzExMg==#wechat_webview_type=1&wechat_redirect"
        >
          <img style="height: 32px" src="../assets/CCU.png" alt="" /> <br />
          <h4>CCU病区</h4>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.hello {
}
.listtitle {
  background-color: white;
  text-align: center;
  img {
    height: 50px;
    text-align: center;
  }
  h4 {
    text-align: center;
  }
}
.list {
  display: flex;
  justify-content: space-around;
}
.title {
  padding: 25px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  height: 80px;
  width: 113px;
  a {
    text-decoration: none;
    color: #333;

    a:hover,
    a:visited,
    a:link,
    a:active {
      color: #333;
      // 设置使所有a标签的四种状态都和本身颜色保持一致，样式代码自己写
    }
  }
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
